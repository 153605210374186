const AboutPage = () => {
  return (
    <div className='aboutPageContainer'>
      <section id='author-photo'>
        <img src='img/photo-author.jpg' alt='Author' />
      </section>
      <article id='about-text'>
        <p>
          Євгеній Бєляєв, викладач англійської мови, перекладач, автор курсів
          «Успішна Англійська» (Англійська мова + Особиста Ефективність,
          Успішність і Тайм-Менеджмент). Завершив Чернівецький Національний
          університет імені Юрія Федьковича серед 10 найкращий студентів випуску
          та отримав диплом спеціаліста з англійської філології. Має більше
          19-ти років викладацького стажу: приватні уроки англійської,
          викладання індивідуально та групам на оффлайн курсах іноземних мов та
          в одній з найпрестижніших онлайн-шкіл – «EnglishDom». Має другу вищу
          освіту – диплом бакалавра з прикладної математики і 6 років досвіду
          комерційної розробки програмного забезпечення. Присвятив 6 років свого
          життя навчанню особистій успішності та ефективності, фінансовій
          грамотності і тайм-менеджменту і є експертом з цих питань. Серед інших
          захоплень: фітнес, бальні танці, соціальні латиноамериканські танці,
          ліпка мініатюр з полімерної глини, психологія стосунків, створення
          настільних ігор та гра на фортепіано.
        </p>
      </article>
    </div>
  )
}

export default AboutPage
