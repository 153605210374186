import React from 'react'

const PoliticsPage = () => {
  return (
    <p className='padding-all'>
      Політика конфіденційності освітнього онлайн-порталу “English with Success”
      <br />
      <br />
      A. Вступ
      <br />
      1. Конфіденційність користувачів нашого освітнього онлайн-порталу має
      велике значення для нас, і ми докладаємо всіх зусиль для забезпечення її
      захисту. Дані правила пояснюють, як ми використовуємо вашу персональну
      інформацію. <br />
      2. Погоджуючись із використанням файлів-cookie при першому відвіданні
      нашого освітнього онлайн-порталу, відповідно до положень даних Правил, Ви
      надаєте нам дозвіл на використання файлів-cookie при кожному вашому
      наступному візиті. <br />
      <br />
      Б. Збір персональних даних <br />
      Збору, зберіганню та використанню підлягають наступні типи персональних
      даних: <br />• Інформація про ваш комп’ютер, включаючи вашу IP-адресу,
      географічне розташування, тип і версію браузера та операційну систему;{' '}
      <br />• Інформація про ваші відвідування та використання цього освітнього
      онлайн-порталу, включаючи реферальні джерела, протяжність візитів,
      переглянуті сторінки та шляхи навігації по порталу; <br />• Інформація про
      адресу вашої електронної пошти, яку Ви використали для реєстрації на
      нашому освітньому онлайн-порталі; <br />• Інформація, яку Ви ввели під час
      створення профілю на нашому освітньому онлайн-порталі – наприклад, ваше
      ім’я, зображення у вашому профілі, стать, дата народження, сімейний
      статус, хобі та інтереси, освіта та місце роботи; <br />• Інформація,
      така, як ваше ім’я та адреса електронної пошти, які Ви вказали під час
      оформлення підписок на наші поштові повідомлення та/або розсилки; <br />•
      Інформація, яку Ви ввели під час використання сервісів нашого освітнього
      онлайн-порталу; <br />• Інформація, яка генерується при використанні
      нашого освітнього онлайн-порталу, включаючи інформацію про час, частоту та
      умови його використання; <br />• Інформація стосовно будь-яких ваших
      покупок, використаних сервісів або транзакцій, які Ви провели через наш
      освітній онлайн-портал, включаючи ваше ім’я, адресу, номер телефону,
      адресу електронної поштової скриньки та інформацію про кредитну карту;{' '}
      <br />• Інформація, яку Ви розмістили на нашому освітньому онлайн-порталі
      з метою публікації її в Інтернеті, включаючи ваше ім’я користувача,
      зображення профілю та зміст вашої публікації; <br />• Інформація, що
      міститься в будь-яких повідомленнях, які Ви надсилали нам електронною
      поштою або через наш освітній онлайн-портал, включаючи зміст повідомлення
      та мета дані; • Будь-яка інша персональна інформація, яку Ви надіслали
      нам. Перед тим, як розкрити для нас персональну інформацію третьої особи,
      Ви маєте отримати згоду цієї особи як на розкриття, так і на обробку цієї
      інформації у відповідності до даних правил. <br />
      <br />
      В. Використання вашої персональної інформації Персональна інформація, яку
      Ви передали нам через наш освітній онлайн-портал, буде використовуватися
      задля цілей, зазначених в цих правилах або на відповідних сторінках нашого
      освітнього онлайн-порталу. Ми можемо використовувати вашу персональну
      інформацію в наступних цілях: <br />• адміністрування нашого освітнього
      онлайн-порталу та бізнесу; <br />• персоналізація нашого освітнього
      онлайн-порталу для Вас; <br />• надання Вам можливості користуватися
      сервісами, доступними на нашому освітньому онлайн-порталі; <br />• надання
      Вам послуг, придбаних через наш освітній онлайн-портал; <br />• надсилання
      Вам сертифікатів про успішне завершення наших курсів; <br />• надсилання
      Вам повідомлень, рахунків та нагадувань про сплату, та отримання платежів
      від вас; <br />• надсилання Вам немаркетингових комерційних повідомлень;{' '}
      <br />• надсилання Вам електронною поштою повідомлень, які Ви спеціально
      запросили; <br />• надсилання Вам електронної розсилки, якщо Ви її
      замовили (Ви в будь-який момент можете повідомити нас, що більше не
      бажаєте отримувати електронні розсилки від нас); <br />• надсилання Вам
      маркетингових повідомлень стосовно нашої ділової активності або ділової
      активності старанно відібраних сторонніх компаній, яка, на нашу думку,
      може Вас зацікавити, шляхом публікацій або, якщо Ви конкретно надали на це
      згоду – шляхом надсилання електронної пошти або за рахунок використання
      подібних технологій (Ви в будь-який момент можете повідомити нас, що
      більше не бажаєте отримувати маркетингові повідомлення); <br />• надання
      стороннім компаніям статистичної інформації про наших користувачів (проте,
      ці сторонні компанії не матимуть змоги ідентифікувати жодного окремого
      користувача з цих даних); <br />• обробка запитів та скарг, зроблених Вами
      або на Вас, і які стосуються нашого освітнього онлайн-порталу; <br />• з
      метою забезпечення безпеки нашого освітнього онлайн-порталу та
      попередження шахрайства; <br />• з метою перевірки відповідності умовам та
      правилам, які регламентують використання нашого освітнього онлайн-порталу
      (включаючи моніторинг приватних повідомлень, надісланих через сервіс
      приватних повідомлень нашого освітнього онлайн-порталу); <br />• та в
      інших цілях в межах договору-оферти. Якщо Ви надали персональну інформацію
      для публікації на нашому освітнього онлайн-порталі, ми опублікуємо її. В
      іншому випадку, ми використовуватимемо цю інформацію у відповідності до
      ліцензії, яку Ви нам надали. <br />
      <br />
      Г. Розкриття персональної інформації Ми залишаємо за собою право розкрити
      вашу персональну інформацію для будь-якого з наших працівників,
      керівників, партнерів, страхувальників, професійних радників, агентів,
      постачальників або субпідрядників, в об’ємі та цілях, визначених в цих
      правилах. Ми залишаємо за собою право розкрити вашу персональну інформацію
      для будь-якого члена нашої групи компаній (сюди входять всі наші дочірні
      компанії) в об’ємі та цілях, визначених в цих правилах. Ми залишаємо за
      собою право розкрити вашу персональну інформацію: <br />• в тих випадках,
      в яких цього від нас вимагає закон; <br />• у зв’язку з будь-яким
      поточними або майбутніми судовими процесами; <br />• з метою встановлення,
      реалізації або захисту наших законних прав (включаючи надання інформації
      іншим сторонам задля запобігання шахрайству або зниження кредитних
      ризиків); <br />• покупцеві (або потенційному покупцеві) будь-якого
      бізнесу або активів, які ми продаємо (або збираємося продати); <br />• та
      будь-якій особі, яка, як ми, обґрунтовано, вважаємо, може подати запит до
      суду або іншого уповноваженого органу про розкриття цих персональних даних
      і, на нашу обґрунтовану думку, цей суд або уповноважений орган може видати
      розпорядження на розкриття цих персональних даних. Ми не будемо розкривати
      вашу персональну інформацію третім особам, за виключенням випадків,
      зазначених в цих правилах.
      <br />
      <br /> Д. Міжнародні передачі персональної інформації Інформація, яку ми
      збираємо, може зберігатися, оброблятися та передаватися між будь-якими
      країнами, в яких ми здійснюємо свою діяльність, з метою надання нам
      можливості використовувати цю інформацію у відповідності з цими правилами.
      Персональна інформація, яку Ви публікуєте на нашому освітньому
      онлайн-порталі, через Інтернет, може бути доступна в усьому світі. Ми не
      можемо перешкодити її використанню, або неправомірному використанню в
      злочинних цілях, сторонніми особами. Погоджуючись з цими правилами, Ви
      надаєте згоду на передачу вашої персональної інформації, зазначеної в
      розділі Д. <br />
      <br />
      Е. Збереження персональної інформації Розділ Е встановлює правила та
      процедури компанії щодо збереження персональної інформації. Дані правила
      та процедури призначені для забезпечення виконання нами наших юридичних
      зобов’язань щодо збереження та видалення персональної інформації.
      Персональна інформація, яку ми обробляємо з певною метою або в певних
      цілях не повинна зберігатися довше, ніж потрібно для досягнення цієї мети
      або цих цілей. <br />
      <br />
      Є. Захист вашої персональної інформації Ми будемо вживати достатні
      технічні та організаційні заходи для попередження втрати, протиправного
      використання чи підробки вашої персональної інформації. Всю надану Вами
      персональну інформацію ми будемо зберігати на захищених (як паролем, так і
      фаєрволами) серверах. Всі електронні фінансові транзакції, здійснені за
      допомогою нашого сайту, будуть захищені технологією шифрування даних. Ви
      підтверджуєте своє ознайомлення з тим фактом, що передача інформації через
      Інтернет є, по суті, незахищеною, і ми не можемо гарантувати захист даних,
      надісланих через всесвітню мережу. Ви несете повну відповідальність за
      збереження свого пароля для доступу до нашого освітнього онлайн-порталу в
      таємниці. Ми ніколи не будемо запитувати ваш пароль (за виключенням
      випадків, коли Ви намагаєтесь увійти до свого облікового запису на нашому
      сайті). <br />
      <br />
      Ж. Зміни та поправки Ми лишаємо за собою право періодично вносити зміни та
      поправки в ці правила, та публікувати їх нову редакцію на нашому
      освітньому онлайн-порталі. Ви повинні періодично перевіряти цю
      веб-сторінку, щоб пересвідчитись, що розумієте зміст змін, внесених до цих
      правил. Ми також можемо проінформувати Вас про внесення змін до цих правил
      шляхом надсилання електронної пошти або через систему передачі приватних
      повідомлень нашого сайту. <br />
      <br />
      З. Ваші права Ви можете надати нам вказівку надавати Вам будь-яку
      персональну інформацію про Вас, яку ми маємо. Ми лишаємо за собою право
      відмовити в наданні інформації за вашим запитом, в межах чинного
      законодавства. Ви маєте право надати нам вказівку не обробляти вашу
      персональну інформацію в маркетингових цілях. На практиці, Ви, зазвичай,
      або завчасно погоджуєтесь з тим, щоб Ви використовували вашу персональну
      інформацію в маркетингових цілях, або ми надамо Вам можливість відмовитися
      від використання вашої інформації в маркетингових цілях. <br />
      <br />
      И. Сторонні веб-сайти Наш освітній онлайн-портал містить гіперпосилання
      на, та деталі про веб-сайти сторонніх компаній та осіб. Ми не маємо
      інструментів керування та не несемо відповідальності за політику
      конфіденційності й практики сторонніх осіб та компаній в цій галузі.{' '}
      <br />
      <br />
      І. Оновлення інформації Будь-ласка, своєчасно повідомляйте нас, якщо ваша
      персональна інформація, яка знаходиться у нас, потребує оновлення чи
      виправлень. <br />
      <br />
      Ї. Файли-Cookies Наш освітній онлайн-портал використовує файли-cookies.
      Cookie — це файл, що містить ідентифікатор (стрічку, яка складається з
      літер та цифр), і який надсилається веб-сервером до веб-браузеру, та
      зберігається браузером. В подальшому, ідентифікатор надсилається назад на
      сервер кожного разу, коли браузер запитує веб-сторінку з серверу.
      Файли-cookies можуть бути або «постійними» або «сеансові»: постійні будуть
      зберігатися браузером та будуть придатними до завершення терміну дії, якщо
      тільки не будуть видалені користувачем завчасно; «сеансові» навпаки –
      будуть видалятися після завершення сеансу роботи з сайтом або після
      закриття браузеру. Файли-cookies зазвичай не містять жодної інформації,
      яка ідентифікує особу користувача. Проте, ваша персональна інформація, яку
      ми маємо, може бути пов’язана з інформацією, що зберігається та отримана
      від файлів-cookies. <br />
      <br />
      Й. Видалення персональної інформації Щоб видалити ваш обліковий запис та
      персональну інформацію з сайту www.english-with-success.com, слід
      натиснути кнопку з написом «Видалити мій обліковий запис» у налаштуваннях
      облікового запису. Оскільки видалення в системі “English with success” є
      остаточним і не може бути скасовано, "English with success" може запросити
      підтвердження ваших повноважень щодо управління цим обліковим записом за
      допомогою електронної пошти. Після запиту на видалення вашого облікового
      запису, “English with success” остаточно: <br />• деактивує ваш обліковий
      запис на освітньому порталі; <br />• видалить активний профіль; <br />•
      вилучить ваше ім’я користувача; <br />• видалить Вас зі списків
      електронної пошти системи. <br />
      Що стосується вашої персональної інформації: "English with success”
      назавжди видалить персональну інформацію вашого облікового запису з
      освітнього онлайн-порталу. Після видалення персональної інформації у
      вашому профілі облікового запису на “English with success”, решта
      інформації про вашу діяльність на сайті www.english-with-success.com
      більше не буде пов’язана з Вами, за винятком випадків, зазначених нижче.
      <br />
      <br /> Винятки: <br />• Видалення не застосовуватиметься до архівів
      історії дій, якщо/ доки вони не «застаріють» у системі “English with
      success”. <br />• “English with success”не відстежує коли та де саме на
      сайті “www.english-with-success.com” слухач може надати особисту
      інформацію, тому ані “English with success”, ані його партнери не зможуть
      допомогти Вам знайти та керувати усіма такими випадками. “English with
      success” закликає Вас користуватися здоровим глуздом перед тим, як
      добровільно ділитися своєю персональною інформацією на сайті
      “www.english-with-success.com”. <br />• “English with success” буде
      архівувати дані курсів (у спосіб і в обсягах, дозволених чинним
      законодавством), з метою досліджень в галузі когнітивної науки та освіти.{' '}
      <br />• “English with success” (та його партнери) не завжди можуть
      видалити записи про минулі взаємодії та транзакції. Наприклад, записи, що
      стосуються попередніх покупок на сайті “www.english-with-success.com”,
      слід зберігати з метою фінансової звітності, аудиту та дотримання вимог.{' '}
      <br />• “English with success” (та його партнери) зберігатиме та
      використовуватиме персональну інформацію, якщо це необхідно для виконання
      своїх юридичних зобов’язань, вирішення суперечок, забезпечення виконання
      своїх домовленостей та в інших випадках, дозволених чинним законодавством.
    </p>
  )
}

export default PoliticsPage
