const newExercise = {
  "exercisePos": 1,
  "exerciseType": "not-quiz",
  "exerciseDescription": "",
  "exerciseQuizAnswers": [
    "",
    "",
    "",
    ""
  ],
  "correctAnswer": ""
}

export default newExercise