import React from 'react'

const OfertaPage = () => {
  return (
    <p className='padding-all'>
      ПУБЛІЧНИЙ ДОГОВІР (ОФЕРТА) <br />
      на купівлю-продаж авторських курсів «Англійська мова й особиста
      ефективність та успішність і тайм-менеджмент» <br />
      <br />
      Цей договір є офіційною та публічною пропозицією Продавця укласти договір
      купівлі-продажу Послуги, представленого на сайті
      www.english-with-success.com. Даний договір є публічним, тобто відповідно
      до статті 633 Цивільного кодексу України, його умови є однаковими для всіх
      покупців незалежно від їх статусу (фізична особа, юридична особа, фізична
      особа-підприємець) без надання переваги одному покупцю перед іншим. Шляхом
      укладення цього Договору покупець в повному обсязі приймає умови та
      порядок оформлення замовлення, оплати послуги, відповідальності за
      недобросовісне замовлення та усі інші умови договору. Договір вважається
      укладеним з моменту натискання кнопки «Оплатити» на сторінці оформлення
      замовлення в Розділі «Курси» і отримання Покупцем від Продавця
      підтвердження купівлі авторського курсу навчання в електронному вигляді.
      <br />
      <br />
      1. Визначення термінів 1.1. <br />
      <br />
      Публічна оферта (далі - «Оферта») - публічна пропозиція Продавця,
      адресована невизначеному колу осіб, укласти з Продавцем договір
      купівлі-продажу Послуги дистанційним способом (далі - «Договір») на
      умовах, що містяться в цій Оферті. <br />
      <br />
      1.2. Послуга – об'єкт угоди сторін, який був обраний покупцем на сайті
      онлайн-порталу, або вже придбаний Покупцем у Продавця дистанційним
      способом. <br />
      <br />
      1.3. Освітній онлайн-портал – сайт Продавця за адресою:
      www.english-with-success.com, створений для укладення договорів
      купівлі-продажу авторських курсів навчання на підставі ознайомлення
      Покупця із запропонованим Продавцем описом Послуги за допомогою мережі
      Інтернет. <br />
      <br />
      1.4. Покупець – дієздатна фізична особа, яка досягла 18 років, отримує
      інформацію від Продавця, розміщує замовлення щодо купівлі послуги, що
      представлений на сайті освітнього онлайн-порталу для цілей, що не
      пов'язані зі здійсненням підприємницької діяльності, або юридична особа чи
      фізична особа-підприємець. <br />
      <br />
      1.5. Продавець – ФОП Бєляєв Є.В. (ідентифікаційний код: 2975915155),
      фізична ооба-підприємець, яка створена і діє відповідно до чинного
      законодавства України, місцезнаходження якої: Україна, 58000, м. Чернівці,
      вул. Карамзіна, буд.5/3 <br />
      <br />
      2. Предмет Договору <br />
      <br />
      2.1. Продавець зобов’язується надати Покупцю Послугу, а Покупець
      зобов’язується оплатити і прийняти Послугу на умовах цього Договору.{' '}
      <br />
      <br />
      2.2. Датою укладення Договору-оферти (акцептом оферти) та моментом повного
      й беззаперечного прийняттям Покупцем умов Договору вважається дата
      заповнення Покупцем форми замовлення, розташованої на сайті освітнього
      онлайн-порталу, за умови отримання Покупцем від Продавця підтвердження
      замовлення в електронному вигляді. <br />
      <br />
      3. Оформлення Замовлення <br />
      <br />
      3.1. Покупець самостійно оформляє замовлення на сайті освітнього
      онлайн-порталу через форму оплати. <br />
      <br />
      3.2. Продавець має право відмовитися від надання Послуги Покупцеві у
      випадку, якщо відомості, вказані Покупцем під час оформлення замовлення, є
      неповними або викликають підозру щодо їх дійсності. <br />
      <br />
      3.3. При оформленні замовлення на сайті освітнього онлайн-порталу Покупець
      зобов'язується надати наступну обов’язкову інформацію, необхідну Продавцю
      для надання Послуги: <br />
      <br />
      3.3.1. прізвище, ім'я Покупця; <br />
      <br />
      3.3.2. адресу електронної пошти; <br />
      <br />
      3.3.3. контактний телефон; <br />
      <br />
      3.3.4. Ідентифікаційний код для юридичної особи або фізичної-особи
      підприємця. <br />
      <br />
      3.4. Найменування, кількість, артикул, ціна обраної Покупцем Послуги
      вказуються на сторінці оплати на сайті освітнього онлайн-порталу. <br />
      <br />
      3.5. Якщо будь-якій із Сторін договору необхідна додаткова інформація,
      вона має право запросити її у іншої Сторони. У разі ненадання необхідної
      інформації Покупцем, Продавець не несе відповідальності за надання якісної
      послуги Покупцю при покупці Послуги на сайті освітнього онлайн-порталу.{' '}
      <br />
      <br />
      3.6. При оформленні замовлення через оператора Продавця (п. 3.1. Цієї
      Оферти) Покупець зобов'язується надати інформацію, зазначену в п. 3.3 –
      3.4. цієї Оферти. <br />
      <br />
      3.7. Ухвалення Покупцем умов цієї Оферти здійснюється за допомогою
      внесення Покупцем відповідних даних в реєстраційну форму на сайті
      освітнього онлайн-порталу або при оформленні Замовлення через оператора.
      Після оформлення Замовлення через Оператора дані про Покупця вносяться до
      бази даних Продавця. <br />
      <br />
      3.8. Покупець несе відповідальність за достовірність наданої інформації
      при оформленні Замовлення. <br />
      <br />
      3.9. Укладаючи Договір, тобто акцептуючи умови даної пропозиції
      (запропоновані умови придбання Послуги), шляхом оформлення Замовлення,
      Покупець підтверджує наступне: а) Покупець цілком і повністю ознайомлений,
      і згоден з умовами цієї пропозиції (оферти); б) він дає дозвіл на збір,
      обробку та передачу персональних даних, дозвіл на обробку персональних
      даних діє протягом усього терміну дії Договору, а також протягом
      необмеженого терміну після закінчення його дії. Крім цього, укладенням
      договору Покупець підтверджує, що він повідомлений (без додаткового
      повідомлення) про права, встановлених Законом України "Про захист
      персональних даних", про цілі збору даних, а також про те, що його
      персональні дані передаються Продавцю з метою можливості виконання умов
      цього Договору, можливості проведення взаєморозрахунків, а також для
      отримання рахунків, актів та інших документів. Покупець також погоджується
      з тим, що Продавець має право надавати доступ та передавати його
      персональні дані третім особам без будь-яких додаткових повідомлень
      Покупця з метою виконання замовлення Покупця. Обсяг прав Покупця, як
      суб'єкта персональних даних відповідно до Закону України "Про захист
      персональних даних" йому відомий і зрозумілий. <br />
      <br />
      4. Ціна і Надання Послуг
      <br />
      <br />
      4.1 Ціни на Послуги визначаються Продавцем самостійно та вказані на сайті
      освітнього онлайн-порталу. Всі ціни на Послуги вказані на сайті у гривнях
      з урахуванням ПДВ. <br />
      <br />
      4.2 Ціни на Послуги можуть змінюватися Продавцем в односторонньому порядку
      залежно від кон'юнктури ринку. При цьому ціна окремої одиниці Послуги,
      вартість якої оплачена Покупцем в повному обсязі, не може бути змінена
      Продавцем в односторонньому порядку. <br />
      <br />
      4.3. Вартість Послуги, яка вказана на сайті освітнього онлайн-порталу не
      включає в себе вартість банківської комісії під час оплати за надання
      Послуги Покупцю. Вартість банківської комісії при оплаті Послуги Покупець
      сплачує відповідно до діючих тарифів банку безпосередньо обраного ним.{' '}
      <br />
      <br />
      4.4. Вартість Послуги, яка вказана на сайті освітнього онлайн-порталу не
      включає в себе вартість банківської комісії при оплаті Послуги Покупцем.
      <br />
      <br />
      4.5. Продавець може вказати орієнтовну вартість банківської комісії при
      оплаті за Послугу Покупцем під час звернення Покупця із відповідним
      запитом до Продавця шляхом надсилання листа на електронну пошту або при
      оформленні замовлення через оператора освітнього онлайн-порталу. <br />
      <br />
      4.6. Зобов'язання Покупця по оплаті Послуги вважаються виконаними з
      моменту надходження Продавцю коштів на його рахунок. <br />
      <br />
      4.7. Розрахунки між Продавцем і Покупцем за Послугу здійснюються
      способами, зазначеними на сайті освітнього онлайн-порталу в розділі
      «Оплата». <br />
      <br />
      4.8. При отриманні Послуги Покупець повинен перевірити в своєму особистому
      кабінеті на освітньому онлайн-порталі наявність доступу до всіх цифрових
      навчальних матеріалів, вказаних в тарифі Послуги і термін їх надання.{' '}
      <br />
      <br />
      5. Права та обов’язки Сторін <br />
      <br />
      5.1. Продавець зобов’язаний: 5.1.1. Надати Покупцеві Послугу у
      відповідності до умов цього Договору та замовлення Покупця. <br />
      <br />
      5.1.2. Не розголошувати будь-яку приватну інформацію про Покупця і не
      надавати доступ до цієї інформації третім особам, за винятком випадків,
      передбачених законодавством та під час виконання Замовлення Покупця.{' '}
      <br />
      <br />
      5.2. Продавець має право: <br />
      <br />
      5.2.1 Змінювати умови цього Договору, а також ціни на Послуги, в
      односторонньому порядку, розміщуючи їх на освітньому онлайн-порталі. Всі
      зміни набувають чинності з моменту їх публікації. <br />
      <br />
      5.3 Покупець зобов'язується: <br />
      <br />
      5.3.1 До моменту укладення Договору ознайомитися зі змістом Договору,
      умовами Договору і цінами на Послуги, запропонованими Продавцем на
      освітньому онлайн-порталі.
      <br />
      <br /> 5.3.2 На виконання Продавцем своїх зобов'язань перед Покупцем
      останній повинен повідомити всі необхідні дані, що однозначно
      ідентифікують його як Покупця, і достатні для надання Покупцеві замовленої
      Послуги.
      <br />
      <br /> 6. Відповідальність <br />
      <br />
      6.1. Продавець не несе відповідальності за відсутність знань Покупця після
      завершення навчання на курсах внаслідок не приділення Покупцем достатнього
      часу та уваги навчанню та виконанню домашніх завдань курсів, придбаних у
      Продавця. <br />
      <br />
      6.2. Продавець не несе відповідальності за неналежне, несвоєчасне
      виконання Замовлень і своїх зобов’язань у випадку надання Покупцем
      недостовірної або помилкової інформації. <br />
      <br />
      6.3. Продавець і Покупець несуть відповідальність за виконання своїх
      зобов'язань відповідно до чинного законодавства України і положень цього
      Договору. <br />
      <br />
      6.4. Продавець або Покупець звільняються від відповідальності за повне або
      часткове невиконання своїх зобов'язань, якщо невиконання є наслідком
      форс-мажорних обставин як: війна або військові дії, землетрус, повінь,
      пожежа та інші стихійні лиха, що виникли незалежно від волі Продавця і /
      або Покупця після укладення цього договору. Сторона, яка не може виконати
      свої зобов'язання, негайно повідомляє про це іншу Сторону. <br />
      <br />
      7. Конфіденційність і захист персональних даних. <br />
      <br />
      7.1. Надаючи свої персональні дані на сайті освітнього онлайн-порталу при
      реєстрації або оформленні Замовлення, Покупець надає Продавцеві свою
      добровільну згоду на обробку, використання (у тому числі і передачу) своїх
      персональних даних, а також вчинення інших дій, передбачених Законом
      України «Про захист персональних даних», без обмеження терміну дії такої
      згоди. <br />
      <br />
      7.2. Продавець зобов'язується не розголошувати отриману від Покупця
      інформацію. Не вважається порушенням надання Продавцем інформації
      контрагентам і третім особам, що діють на підставі договору з Продавцем, в
      тому числі і для виконання зобов'язань перед Покупцем, а також у випадках,
      коли розкриття такої інформації встановлено вимогами чинного законодавства
      України. <br />
      <br />
      7.3. Покупець несе відповідальність за підтримання своїх персональних
      даних в актуальному стані. Продавець не несе відповідальності за неякісне
      виконання або невиконання своїх зобов'язань у зв'язку з неактуальністю
      інформації про Покупця або невідповідністю її дійсності. <br />
      <br />
      8. Інші умови <br />
      <br />
      8.1. Цей договір укладено на території України і діє відповідно до чинного
      законодавства України. <br />
      <br />
      8.2. Усі спори, що виникають між Покупцем і Продавцем, вирішуються шляхом
      переговорів. У випадку недосягнення врегулювання спірного питання шляхом
      переговорів, Покупець та/або Продавець мають право звернутися за
      вирішенням спору до судових органів відповідно до чинного законодавства
      України. <br />
      <br />
      8.3. Продавець має право вносити зміни до цього Договору в односторонньому
      порядку, передбаченому п. 5.2.1. Договору. Крім того, зміни до Договору
      також можуть бути внесені за взаємною згодою Сторін в порядку,
      передбаченому чинним законодавством України. <br />
      <br />
      АДРЕСА ТА РЕКВІЗИТИ ПРОДАВЦЯ: ФОП Бєляєв Є.В. <br />
      <br />
      Україна, 58000, м. Чернівці, вул. Карамзіна, буд. 5/3 <br />
      П/р: UA903510050000026001878954803 в АТ «Укрсиббанк» <br />
      МФО: 351005 <br />
      Код/ЄДРПОУ банку: 09807750 <br />
      ІПН: 2975915155 <br />
      Тел. (066) 229-77-67
    </p>
  )
}

export default OfertaPage
